@keyframes scaleImage {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.8);
  }
}

.global-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 999999999999;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    animation: scaleImage 1s linear infinite;
  }
}

.page-loader-container {
  min-height: 500px;

  .page-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      animation: scaleImage 1s linear infinite;
    }
  }
}
