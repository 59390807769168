hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #000;
}

h1,
.h1 {
  font-size: calc(1.35rem + 1.2vw) !important;
}
@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 2.25rem !important;
  }
}

h2,
.h2 {
  font-size: calc(1.3125rem + 0.75vw) !important;
}
@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 1.875rem !important;
  }
}

h3,
.h3 {
  font-size: calc(1.275rem + 0.3vw) !important;
}
@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 1.5rem !important;
  }
}

h4,
.h4 {
  font-size: 1.125rem !important;
}

h5,
.h5 {
  font-size: 1rem !important;
}

h6,
.h6 {
  font-size: 0.938rem !important;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.ck.ck-editor {
  border-radius: 8px !important;
  border: 1px solid #ccc !important;
  overflow: hidden;
}

.ck.ck-toolbar {
  border-top-width: 0 !important;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
}

.ck.ck-content {
  border-bottom-width: 0 !important;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
}

/*# sourceMappingURL=style.css.map */
