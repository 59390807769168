body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.avatar-comment {
  width: 80px;
  height: 80px;
}

.background-header {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

.may-background::before {
  content: '';
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-image: url('./assets/img/bg/may.png');
  background-size: calc(100vw / 3) auto;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: -1;
  opacity: 0.3;
}

.choose-language {
  font-family: 'OpenSansCondensed-Light';
  color: #fff;
  font-weight: 900;
  cursor: pointer;
  font-size: 16px;
}

.choose-language:hover {
  text-shadow: 0 0 10px white;
}

.square-div:before {
  content: '';
  float: left;
  padding-top: 100%; /* initial ratio of 1:1*/
}

.image-container {
  width: 100%;
  height: 100%;
  display: flex;
  /* justify-content: center;
  align-items: center; */
  border-radius: 8px;
  background-color: #eee;
  cursor: pointer;
  overflow: hidden;
}

.language-mobile {
  position: absolute;
  right: 70px;
  top: -43px;
  font-size: 18px;
  font-weight: 700;
}

.background-white {
  background-color: white;
}

.padding-20 {
  padding: 20px;
}

.slick-initialized .slick-track {
  display: flex;
}

.custom-btn {
  height: 40px;
  padding: 0px 20px !important;
}

.room-detail-image-1 .image {
  height: 100%;
  overflow: hidden;
}

.room-detail-image-1 .image img {
  height: 100%;
  object-fit: cover;
}

.room-detail-image-2-3 {
  display: flex;
  flex-direction: column;
}

.room-detail-image-2-3 {
  display: flex;
  flex-direction: column;
}

.room-detail-image-2-3 .image {
  margin: 0;
  overflow: hidden;
}

.room-detail-image-2-3 .image:first-child {
  margin-bottom: 1px;
}

.room-detail-image-2-3 .image img {
  height: 100%;
  object-fit: cover;
}

@media (min-width: 1200px) {
  .room-detail-image-1 {
    height: 600px;
  }

  .room-detail-image-2-3 {
    height: 600px;
  }

  .room-detail-image-2-3 {
    height: 600px;
  }

  .room-detail-image-2-3 .image {
    height: 300px;
  }
}

@media (min-width: 1200px) {
  .room-detail-image-1 {
    height: 600px;
  }

  .room-detail-image-2-3 {
    height: 600px;
  }

  .room-detail-image-2-3 {
    height: 600px;
  }

  .room-detail-image-2-3 .image {
    height: 300px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .room-detail-image-1 {
    height: 700;
  }

  .room-detail-image-2-3 {
    height: 700px;
  }

  .room-detail-image-2-3 {
    height: 700px;
  }

  .room-detail-image-2-3 .image {
    height: 400px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .room-detail-image-1 {
    height: 700;
  }

  .room-detail-image-2-3 {
    height: 700px;
  }

  .room-detail-image-2-3 {
    height: 700px;
  }

  .room-detail-image-2-3 .image {
    height: 400px;
  }
}

.s-about-img img {
  width: 70%;
  border-radius: 5px;
  max-height: 650px;
  min-height: 450px;
  object-fit: cover;
}

.about-icon {
  display: inline-block;
  width: 60%;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
}

.about-icon img {
  width: 100%;
  max-height: 500px;
  min-height: 300px;
  object-fit: cover;
}

.skills-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section3-image-home img {
  width: 100%;
  object-fit: cover;
}

.slider-area {
}

.ck.ck-toolbar {
  border: none !important;
}

.ck.ck-editor__editable_inline {
  border: none !important;
  background-color: transparent !important;
  padding: 0 !important;
}

.list-style-none {
  list-style-type: none !important;
}

.ck-content ul,
.ck-content ol {
  padding-left: 22px !important;
}

.image-container-homepage-section {
  width: 100% !important;
  padding-top: 100% !important;
  margin-bottom: 20px;
}

.image-homepage-section {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  margin-bottom: 0 !important;
  min-height: unset !important;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .ck-table-resized tr {
    display: flex;
    width: 92vw;
    flex-direction: column;
  }

  .ck-table-resized tr:nth-child(2n) td:first-child {
    order: 1;
  }

  .ck-table-resized tr:nth-child(2n) td:last-child {
    order: 0;
  }

  .ck-table-resized tr:nth-child(2n + 1) td:first-child {
    order: 0;
  }

  .ck-table-resized tr:nth-child(2n + 1) td:last-child {
    order: 1;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  right: 6px;
  top: 12px;
}

.react-datepicker__view-calendar-icon input {
  padding: 6px 40px 5px 10px !important;
}

.custom-input-date {
  width: 100%;
  background: #fff;
  padding: 10px;
  transition: 0.3s;
  border: 1px solid #64422247;
  cursor: pointer;
  font-size: 17px;
  height: 60px;
  color: #495057;
}

.custom-input-date:active {
  border: 2px solid blue;
  border-radius: 2px;
}
